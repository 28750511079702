import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

//  const API_URL = "http://localhost:8802/api";

 const API_URL = "https://back-demo-project-management.talentfort.live/api";

const baseQuery = fetchBaseQuery({ baseUrl: API_URL });

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [],
  endpoints: (builder) => ({}),
});
